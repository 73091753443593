/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import block3Image from "../images/block3-header.png"
import block3Imagesp from "../images/block03-sp-header.png"
import featureImage from "../images/block3-features.png"
import featureImagesp from "../images/block03-sp-features.png"
import planboxImage1 from "../images/planMovie01.png"
import planboxImage2 from "../images/planMovie02.png"

const Plan = () => {
  return (
    <div
      sx={{
        background: "#E5F0FF",
        padding: "38px 120px",
        textAlign: "center",
        "@media (max-width: 1300px)": {
          padding: "38px 60px",
        },
        "@media (max-width: 1100px)": {
          padding: "38px 30px",
        },
        "@media (max-width: 1000px)": {
          padding: "38px 15px",
        },
      }}
    >
      <img
        src={block3Image}
        alt={"プラン・特徴"}
        sx={{
          position: "relative",
          top: -60,
          width: "100%",
          maxWidth: 1000,

          "@media (max-width: 1024px)": {
            display: "none",
          },
        }}
      />
      <img
        src={block3Imagesp}
        alt={"プラン・特徴"}
        sx={{
          width: "100%",
          maxWidth: 600,
          display: "none",
          "@media (max-width: 1024px)": {
            display: "inline",
          },
        }}
      />
      <div
        sx={{
          maxWidth: 1200,
          display: "flex",
          justifyContent: "space-between",

          margin: "-80px auto 80px",
          alignContent: "center",
          zIndex: 0,
          "@media (max-width: 1100px)": {
            flexDirection: "column",
            marginTop: -30,
          },
        }}
      >
        <div
          sx={{
            margin: 0,
            position: "relative",
            flexDirection: "column",
          }}
        >
          <img
            src={planboxImage1}
            alt={"プラン映画"}
            sx={{
              height: "auto",
              "@media (max-width:484px)": {
                height: "560px",
              },
            }}
          />

          <iframe
            width="451"
            height="273"
            src="https://www.youtube.com/embed/Vajrm3KYZb4"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            sx={{
              display: "inline-block",
              position: "relative",
              top: "-12%",
              right: 0,
              left: 0,
              marginTop: -900,

              "@media (max-width: 1100px)": {
                top: -80,
              },
              "@media (max-width:545px)": {
                marginBottom: 0,
                width: "95%",
                padding: "40px 20px 0px",
                overflow: "hidden",
                boxSizing: "border-box",
              },
            }}
          ></iframe>
        </div>
        <div
          sx={{
            margin: 0,
            position: "relative",
            flexDirection: "column",
          }}
        >
          <img
            src={planboxImage2}
            alt={"プラン映画"}
            sx={{
              height: "auto",
              "@media (max-width:484px)": {
                height: "560px",
              },
            }}
          />
          <iframe
            width="451"
            height="273"
            src="https://www.youtube.com/embed/1w4sCUe0wAo"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            sx={{
              display: "inline-block",
              position: "relative",
              top: "-12%",
              right: 0,
              left: 0,
              marginTop: -900,

              "@media (max-width: 1100px)": {
                top: -80,
              },
              "@media (max-width:545px)": {
                width: "95%",
                padding: "40px 20px 0px",
                overflow: "hidden",
                boxSizing: "border-box",
              },
            }}
          ></iframe>
        </div>
      </div>
      <img
        src={featureImage}
        alt={"プラン・特徴"}
        sx={{
          width: "100%",
          maxWidth: 1000,
          "@media (max-width: 1000px)": {
            display: "none",
          },
        }}
      />
      <div sx={{ marginTop: 80 }}>
        <img
          src={featureImagesp}
          alt={"プラン・特徴"}
          sx={{
            width: "100%",
            maxWidth: 600,
            display: "none",
            "@media (max-width: 1000px)": {
              display: "inline",
            },
          }}
        />
      </div>
      <div id={"works"} />
    </div>
  )
}

export default Plan
