/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import block5Image from "../images/block05.png";
import block5ImageSp from "../images/block05-sp.png";

const Flow = () => {
    return (
        <div sx={{
            background: "#E5F0FF",
            padding: "38px 120px",
            textAlign: "center",
            "@media (max-width: 1300px)": {
                padding: "38px 60px",
            },
            "@media (max-width: 1100px)": {
                padding: "38px 30px",
            },
            "@media (max-width: 1000px)": {
                padding: "38px 15px",
            },
        }}>
            <img src={block5Image} alt={"発注・制作の流れ"} sx={{
                position: "relative",
                top: -60,
                width: "100%",
                maxWidth: 1000,
                "@media (max-width: 1000px)": {
                    display: "none",
                }
            }} />
            <img src={block5ImageSp} alt={"発注・制作の流れ"} sx={{
                position: "relative",
                top: -60,
                width: "100%",
                maxWidth: 600,
                display: "none",
                "@media (max-width: 1000px)": {
                    display: "inline",
                }

            }} />
            <div id={"faq"} />
        </div>
    )
}

export default Flow
