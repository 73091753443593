/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import block1Image from "../images/block01.png";
import block1ImageSp from "../images/block01-sp.png";

const Issues = () => {
    return (
        <div sx={{
            background: "#E5F0FF",
            padding: "38px 120px",
            textAlign: "center",
            "@media (max-width: 1300px)": {
                padding: "38px 60px",
            },
            "@media (max-width: 1100px)": {
                padding: "38px 30px",
            },
            "@media (max-width: 1000px)": {
                padding: "38px 15px",
            }
        }}>
            <img src={block1Image} alt={"宣伝・広告でお悩みの方へ！こんな悩みはありませんか"} sx={{
                width: "100%",
                maxWidth: 1000,
                "@media (max-width: 1000px)": {
                    display: "none",
                }

            }} />
            <img src={block1ImageSp} alt={"宣伝・広告でお悩みの方へ！こんな悩みはありませんか"} sx={{
                width: "100%",
                maxWidth: 600,
                display: "none",
                "@media (max-width: 1000px)": {
                    display: "inline",
                }

            }} />
            <div id={"benefits"} />
        </div>
    )
}

export default Issues
