/** @jsx jsx */
import { jsx } from "theme-ui"
import React, {useEffect, useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from 'aos';
import * as firebase from "firebase/app";
import "firebase/firestore";
import validator from 'validator';
import 'aos/dist/aos.css';
import submitButtonImage from "../images/submit.png"
import Lead from "../components/lead";
import Issues from "../components/issues";
import Benefits from "../components/benefits";
import Plan from "../components/plan";
import Work from "../components/work";
import Flow from "../components/flow";
import Faq from "../components/faq";

const FIREBASE_API_KEY = process.env.GATSBY_FIREBASE_API_KEY;
const FIREBASE_AUTH_DOMAIN = process.env.GATSBY_FIREBASE_AUTH_DOMAIN;
const FIREBASE_PROJECT_ID = process.env.GATSBY_FIREBASE_PROJECT_ID;
const FIREBASE_STORAGE_BUCKET = process.env.GATSBY_FIREBASE_STORAGE_BUCKET;
const FIREBASE_MESSAGING_SENDER_ID = process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID;
const FIREBASE_APP_ID = process.env.GATSBY_FIREBASE_APP_ID;
const FIREBASE_APP_MEASUREMENT_ID = process.env.GATSBY_FIREBASE_APP_MEASUREMENT_ID;

const IndexPage = () => {
    const [sending, setSending] = useState(false);
    const [values, setValues] = useState({
        contact: "メールでも電話でも良い"
    });
    const [errors, setErrors] = useState({});
    const [done, setDone] = useState(false);

    useEffect(() => {
        AOS.init();
    }, []);

    const updateValues = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (sending) {
            return;
        }

        setSending(true);

        const errors = {};
        if (validator.isEmpty(values.contact || "")) {
            errors.purpose = "選択してください。";
        }

        if (validator.isEmpty(values.company || "")) {
            errors.company = "ご記入ください。";
        }

        if (validator.isEmpty(values.name || "")) {
            errors.name = "ご記入ください。";
        }

        if (validator.isEmpty(values.phone || "")) {
            errors.phone = "ご記入ください。";
        }

        if (validator.isEmpty(values.mail || "")) {
            errors.mail = "ご記入ください。";
        } else if (!validator.isEmail(values.mail || "")) {
            errors.mail = "メールアドレスが正しくありません。";
        }

        if (validator.isEmpty(values.message || "")) {
            errors.message = "ご記入ください。";
        }

        if (Object.keys(errors).length !== 0) {
            setErrors(errors);
            setSending(false);
            return;
        } else {
            setErrors([]);
        }

        const firebaseConfig = {
            apiKey: FIREBASE_API_KEY,
            authDomain: FIREBASE_AUTH_DOMAIN,
            projectId: FIREBASE_PROJECT_ID,
            storageBucket: FIREBASE_STORAGE_BUCKET,
            messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
            appId: FIREBASE_APP_ID,
            measurementId: FIREBASE_APP_MEASUREMENT_ID,
        };

        firebase.initializeApp(firebaseConfig);
        const db = firebase.firestore();
        db.collection("contacts").add({
            contact: values.contact,
            company: values.company,
            name: values.name,
            phone: values.phone,
            mail: values.mail,
            message: values.message,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        setSending(false);
        setDone(true);
    }

    return (
        <div>
            <Layout isTop={true}>
                <SEO title="Home" />

                <Lead />

                <Issues />

                <Benefits />

                <Plan />

                <Work />

                <Flow />

                <Faq />

                <div id={"contact"} className={"contact_form"}>
                    <div className="contact">
                        <p>お問い合わせ</p>
                        <p className="borderBottom"> </p>
                    </div>
                    <div className="callOrMAil">
                        <div className="CallingUs">
                            <h2>お電話でのお問い合わせ</h2>
                            <p className="tel"><a href="tel:03-6820-2836">
                                03-6820-2836<span style={{"font-size": "50%"}}>（代表）</span>
                            </a></p>
                            <small className="openTime">営業時間　10:00 〜 19:00</small>
                        </div>

                        <div className="mailUs">
                            <h3>メールでお問い合わせ</h3>
                        </div>

                        {done === true && <div id={"contact"} style={{
                            marginBottom: 150,
                            fontSize: 18,
                            lineHeight: 1.5,
                            color: "#646464",
                        }}>
                            <p>この度はお問い合わせ頂き誠にありがとうございます。</p>
                            <p>お問い合わせ内容を確認し折り返し連絡いたしますので、今しばらくお待ち下さい。</p>
                        </div>}


                        {done !== true && <div className="formWrapper">
                            <form>
                                <div className="flexWrap">
                                    <label htmlFor="company">会社名.法人名</label>
                                    <div>
                                        <input
                                            className="inputStyle"
                                            type="text"
                                            placeholder="例）SNSマンガラボ"
                                            name="company"
                                            id="company"
                                            value={values.company || ""}
                                            onChange={updateValues}
                                        />
                                        {errors.company && <p className={"form-error"}>{errors.company}</p>}
                                    </div>
                                </div>
                                <div className="flexWrap">
                                    <label htmlFor="name">お名前</label>
                                    <div>
                                        <input
                                            className="inputStyle"
                                            type="text"
                                            placeholder="例）山田　太郎"
                                            name="name"
                                            value={values.name || ""}
                                            onChange={updateValues}
                                        />
                                        {errors.name && <p className={"form-error"}>{errors.name}</p>}
                                    </div>
                                </div>
                                <div className="flexWrap">
                                    <label htmlFor="mail">メールアドレス</label>
                                    <div>
                                        <input
                                            className="inputStyle"
                                            type="text"
                                            placeholder="例）info@snsmagazine.com"
                                            name="mail"
                                            value={values.mail || ""}
                                            onChange={updateValues}
                                        />
                                        {errors.mail && <p className={"form-error"}>{errors.mail}</p>}
                                    </div>
                                </div>
                                <div className="flexWrap">
                                    <label htmlFor="phone">お電話番号</label>
                                    <div>
                                        <input
                                            className="inputStyle"
                                            type="tel"
                                            placeholder="例）0120-3456-7890"
                                            name="phone"
                                            value={values.phone || ""}
                                            onChange={updateValues}
                                        />
                                        {errors.phone && <p className={"form-error"}>{errors.phone}</p>}
                                    </div>
                                </div>
                                <div className="flexWrap">
                                    <label htmlFor="message">お問い合わせ内容</label>
                                    <div>
                                    <textarea
                                        className="inputStyle2"
                                        name="message"
                                        value={values.message || ""}
                                        placeholder="お問い合わせ内容を入力してください。"
                                        onChange={updateValues}
                                    />
                                        {errors.message && <p className={"form-error"}>{errors.message}</p>}
                                    </div>
                                </div>

                                <p className="footerP" sx={{
                                    marginBottom: 15,
                                    fontWeight: "bold"
                                }}>
                                    弊社からのご回答方法を以下よりお選びください。
                                </p>
                                <label className="radioBot">
                                    <input
                                        checked={values.contact === "メールでも電話でも良い"}
                                        name="contact"
                                        type="radio"
                                        value="メールでも電話でも良い"
                                        onChange={updateValues}
                                    />
                                    メールでも電話でも良い
                                </label>
                                <label className="radioBot">
                                    <input
                                        checked={values.contact === "メールを希望"}
                                        name="contact"
                                        type="radio"
                                        value="メールを希望"
                                        onChange={updateValues}
                                    />
                                    メールを希望
                                </label>
                                <label className="radioBot">
                                    <input
                                        checked={values.contact === "電話を希望"}
                                        name="contact"
                                        type="radio"
                                        value="電話を希望"
                                        onChange={updateValues}
                                    />
                                    電話を希望
                                </label>
                                <p sx={{
                                    marginTop: 50
                                }}>
                                    <img onClick={handleSubmit} src={submitButtonImage} sx={{
                                        cursor: "pointer",
                                        opacity: sending? 0.5 : 1
                                    }} alt={"お問い合わせ内容を送信"}/>
                                </p>
                            </form>
                        </div>}
                    </div>
                </div>

            </Layout>
        </div>
    )
}

export default IndexPage
