/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import block4Image from "../images/block04.png";
import block4ImageSp from "../images/block04-sp.png";

const Work = () => {
    return (
        <div sx={{
            background: "#FFF",
            padding: "38px 120px",
            textAlign: "center",
            "@media (max-width: 1300px)": {
                padding: "38px 60px",
            },
            "@media (max-width: 1100px)": {
                padding: "38px 30px",
            },
            "@media (max-width: 1000px)": {
                padding: "38px 15px",
            },
        }}>
            <img src={block4Image} alt={"制作実績"} sx={{
                position: "relative",
                top: -60,
                width: "100%",
                maxWidth: 1000,
                "@media (max-width: 1000px)": {
                    display: "none",
                }
            }} />
            <img src={block4ImageSp} alt={"制作実績"} sx={{
                position: "relative",
                top: -60,
                width: "100%",
                maxWidth: 600,
                display: "none",
                "@media (max-width: 1000px)": {
                    display: "inline",
                }

            }} />
            <div id={"flow"} />
        </div>
    )
}

export default Work
