/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import backgroundImage from "../images/bg.png";
import copyImage from "../images/sub.png";
import contactButton from "../images/btn.png";
import girlImage from "../images/girl1.png";
import backgroundImageSp from "../images/bg-sp.png";
import copyImageSp from "../images/sub-sp.png";
import girlImageSp from "../images/girl1-sp.png";

const Lead = () => {
    return (
        <div id={"top"}>
            <div sx={{
                overflow: "hidden",
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                textAlign: "center",
                position: "relative",
                minHeight: 720,
                "@media (max-width: 1000px)": {
                    display: "none"
                }
            }}>
                <div sx={{
                    marginLeft: -250
                }}>
                    <div sx={{
                        marginBottom: 60,
                        paddingTop: 120
                    }}>
                        <img src={copyImage} alt={""} sx={{
                            maxWidth: 709
                        }} />
                    </div>

                    <div>
                        <a href={"/#contact"} sx={{
                            "&:hover": {
                                opacity: 1
                            }
                        }}>
                            <img src={contactButton} sx={{
                                maxWidth: 560
                            }} />
                        </a>
                    </div>
                </div>

                <img src={girlImage} alt={""} sx={{
                    maxHeight: 730,
                    position: "absolute",
                    bottom: 0,
                    marginLeft: 200
                }} />
            </div>

            <div sx={{
                backgroundImage: `url(${backgroundImageSp})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                textAlign: "center",
                position: "relative",
                minHeight: 620,
                display: "none",
                "@media (max-width: 1000px)": {
                    display: "block"
                }
            }}>
                <div sx={{
                    paddingTop: 50,
                    width: "90%",
                    margin: "auto",
                    marginBottom: 30,
                }}>
                    <img src={copyImageSp} alt={""} sx={{
                        width: "100%",
                        maxWidth: 709
                    }} />
                </div>

                <div>
                    <img src={girlImageSp} alt={""} sx={{
                        width: "60%",
                        maxWidth: 400,
                        display: "block",
                        margin: "auto",
                        paddingBottom: 40
                    }} />
                </div>
                <div sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%"
                }}>
                    <a href={"/#contact"} sx={{
                        display: "inline-block",
                        margin: "auto",
                        "&:hover": {
                            opacity: 1
                        }
                    }}>
                        <img src={contactButton} sx={{
                            width: "90%",
                            maxWidth: 560
                        }} />
                    </a>
                </div>
            </div>
            <div id={"issues"} />
        </div>
    )
}

export default Lead
