/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import ttl06Image from "../images/ttl06.png";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";

const Faq = () => {
    return (
        <div className={"question_part"}>
            <div className={"question_ttl"}>
                <img src={ttl06Image} className="cloud" alt={"よくある質問"} />
            </div>
            <div className={"question_list"}>
                <div className="accodionWrapper">
                    <Accordion className="accordion">
                        <AccordionItem className="accordionItem">
                            <AccordionItemHeading className="head">
                                <AccordionItemButton className="button">
                                    <span>Q1</span>
                                    絵コンテやストーリーが書けません。全てお任せできますか？
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="accordingA">
                                <p className="accordingAnswer">
                                    <span className="answer">Answer</span>
                                    <br />
                                    ネタ元があれば当社所属のイラストレーターやシナリオライターが対応いたしますので、ご安心ください。
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem className="accordionItem">
                            <AccordionItemHeading className="head">
                                <AccordionItemButton className="button">
                                    <span>Q2</span>絵や声の雰囲気の希望は出せますか？
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="accordingA">
                                <p className="accordingAnswer">
                                    <span className="answer">Answer</span>
                                    <br />
                                    個性豊かなクリエイターが多数在籍しておりますので、イメージを具体的にお伝えください。サンプルのご用意も可能です。
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem className="accordionItem">
                            <AccordionItemHeading className="head">
                                <AccordionItemButton className="button">
                                    <span>Q3</span>定期的に発注できますか？
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="accordingA">
                                <p className="accordingAnswer">
                                    <span className="answer">Answer</span>
                                    <br />高品質な作品をスピーディーに納品します。シリーズものや大量発注もおまかせください。
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem className="accordionItem">
                            <AccordionItemHeading className="head">
                                <AccordionItemButton className="button">
                                    <span>Q4</span>料金プランは一つでしょうか？
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="accordingA">
                                <p className="accordingAnswer">
                                    <span className="answer">Answer</span>
                                    <br />
                                    <p>選べる2つのお支払いプランをご用意しています。</p>
                                    <p>
                                        <strong>・売り切り型プラン（1動画あたりの販売）</strong><br />
                                        1動画ごとの販売プランです。売り切りなので、購入後は自由にお使い頂けます。
                                    </p>
                                    <p>
                                        <strong> ・レベニューシェア（動画は無料でご提供。成果報酬でプロフィットを分け合う方式）
                                        </strong><br />
                                        動画作成費は無料で行い、成果報酬を頂くプランです。動画運用後、利益の中から決められた割合の料金をお支払いいただきます。
                                    </p>

                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default Faq
